<template>
  <div class="Header" :style="'background-color:'+color.background">
    <el-tooltip :content="'共计：'+stringToJson(title)+' 节课程'" placement="top">
    <div :style="'text-align:center;color:'+color.color"><h3>{{title.name}}</h3></div>
    </el-tooltip>
    <el-divider></el-divider>
    <div v-for="(item,index) in object.List" :key="index">
      <el-tooltip :content="item.name +' '+ item.number+' 位'" placement="top">
        <div class="number" :style="'color:'+color.color">
          <div style="font-size: 14px;">{{item.name}}</div>
          <div style="display: flex">
            <div style="font-size: 20px;" :class="color.two"></div>
            <div style="font-size: 16px;width: 80px;margin:2px 0 0 16px">
              <count-to :startVal='0' :endVal="item.number" :duration='6000' :prefix="prefix"/>
            </div>
          </div>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import countTo from "@/views/statistical/component/countTo";
export default {
  name: "courseStatistical",
  components:{countTo},
  props:{
    title:{
      default() {
        return {}
      }
    },
    object:{
      default() {
        return {
        }
      }
    },
    color:{
      default() {
        return {}
      }
    },
    prefix:{
      default() {
        return {

        }
      }
    },
  },
  data() {
  },
  methods:{
    stringToJson(val){
        return val.number==undefined? '0':val.number.num;
    }
  }
}
</script>

<style scoped>
.number{
  display: flex;
  margin-top: 10px;
  padding: 0 10px;
  width: 320px;
  justify-content: space-between;
}
.Header{
  background-color: #ffffff;
  padding: 30px 20px;
  border-radius: 20px;
  box-shadow: 0 2px 12px 0 rgba(120, 120, 120, 0.1);
}

</style>