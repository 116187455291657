<template>
  <div>
    <div class="statistical">
      <courseStatistical style="width: 340px"  :title="{name:'本日课时',number:countHeader.to}"  prefix="" :color="headerStyle.day" :object="countHeader.to"/>
      <courseStatistical style="width: 340px"  :title="{name:'昨天课时',number:countHeader.yester}"  prefix="" :color="headerStyle.lastDay" :object="countHeader.yester"/>
      <courseStatistical style="width: 340px"  :title="{name:'本周课时',number:countHeader.week}"  prefix="" :color="headerStyle.mouth" :object="countHeader.week"/>
      <courseStatistical style="width: 340px"  :title="{name:'本月课时',number:countHeader.month}"  prefix="" :color="headerStyle.year" :object="countHeader.month"/>
    </div>
  <div class="statistical" style="margin-top: 50px">
    <el-card shadow="always" v-loading="pieChartLoading">
      <div class="statistical">
        <h3 style="text-align: left;line-height: 0;margin-top: 10px">课时数量</h3>
        <div style="display: flex">
          <div>
            <el-select size="small" placeholder="类型" v-model="pieChart.flag" style="width: 140px;margin-right: 10px"  @change="selectFlag">
              <div v-if="sysStudentType==3">
                <el-option label="中招体育" value="1"></el-option>
                <el-option label="少年" value="2"></el-option>
                <el-option label="高考体育" value="4"></el-option>
              </div>
              <div v-if="sysStudentType!=3">
                <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                <el-option v-else label="少年" value="2"></el-option>
                <el-option label="高考体育" value="4"></el-option>
              </div>
            </el-select>
          </div>
          <div>
            <el-date-picker
                style="width: 240px"
                size="small"
                v-model="pieChartDate"
                type="daterange"
                range-separator=" 至 "
                start-placeholder="开始时间"
                end-placeholder="结束时间"
            >
            </el-date-picker>
          </div>
          <div style="padding:8px 20px 0 40px;width: 0px" @click="handleFullScreen">
            <el-tooltip :content="`全屏`">
              <span class="el-icon-rank"></span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <bread-view style="width: 750px; height: 400px;" v-on:detailed="detailed" :data="pieChartData.data" :data-info="pieChartData.info"/>
    </el-card>
    <el-card shadow="always" v-loading="histogramLoading">
      <div class="statistical">
        <h3 style="text-align: left;line-height: 0;margin-top: 10px">课时学员</h3>
        <div style="display: flex">
          <div>
            <el-select size="small" placeholder="类型" v-model="histogram.flag" style="width: 140px;margin-right: 10px"  @change="getFlag">
              <div v-if="sysStudentType==3">
                <el-option label="中招体育" value="1"></el-option>
                <el-option label="少年" value="2"></el-option>
                <el-option label="高考体育" value="4"></el-option>
              </div>
              <div v-if="sysStudentType!=3">
                <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                <el-option v-else label="少年" value="2"></el-option>
                <el-option label="高考体育" value="4"></el-option>
              </div>
            </el-select>
          </div>
          <div>
            <el-date-picker
                style="width: 240px"
                size="small"
                v-model="histogramDate"
                type="daterange"
                range-separator=" 至 "
                start-placeholder="开始时间"
                end-placeholder="结束时间"
            >
            </el-date-picker>
          </div>

          <div style="padding:8px 20px 0 40px;width: 0px" @click="handleFullScreen">
            <el-tooltip :content="`全屏`">
              <span class="el-icon-rank"></span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <pillars-view  style="width: 750px; height: 400px;" v-on:histogramDetailed="histogramDetailed" :series="histogramData" :project="histogramProject.name"/>
    </el-card>
  </div>
    <el-card shadow="always" v-loading="coachCourseLoading">
      <div class="statistical">
        <h3 style="text-align: left;line-height: 0;margin-top: 10px">教练课时</h3>
        <div style="display: flex">
          <select-area size="small" @areaListId="getAreaListId"/>
          <div>
            <el-date-picker
                style="width: 240px"
                size="small"
                v-model="coachCourseDate"
                type="daterange"
                range-separator=" 至 "
                start-placeholder="开始时间"
                end-placeholder="结束时间"
            >
            </el-date-picker>
          </div>
          <div style="padding:8px 20px 0 40px;width: 0px" @click="handleFullScreen">
            <el-tooltip :content="`全屏`">
              <span class="el-icon-rank"></span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <pillars-view  style="width:100%; height: 400px;" :series="coachCourseData" :project="coachCourseProject.name"/>
    </el-card>
  </div>
</template>

<script>
import BreadView from "@/views/statistical/component/BreadView";
import PillarsView from "@/views/statistical/component/PillarsView";
import courseStatistical from "@/views/statistical/component/courseStatistical";
import {courseBreadSummary,courseHeaderSummary,courseHistogramSummary,coachCourseStatistical} from "@/api/count";
import selectArea from "../../components/SelectArea";
import SelectArea from "../../components/SelectArea";
export default {
  props:{
    selectArea
  },
  name: "Courses",
  components: {SelectArea, BreadView,courseStatistical,PillarsView},
  data() {
    return {
      sysStudentType:localStorage.getItem('studentType'),
      pieChartLoading:false,
      pieChartDate:"",//饼状图时间
      pieChart:{
        endDate:"",
        regionId:null,
        flag:null,
        startDate:"",
        type:""
      },//饼状图筛选条件
      pieChartData:{info:{type:"order2",location:'left',center:['60%','50%']}, data:[]},

      histogramLoading:false,
      histogramDate:"",//柱状图时间
      histogram:{
        endDate:"",
        regionId:null,
        flag:null,
        startDate:"",
      },//柱状图筛选条件
      histogramData: [],
      histogramProject:{},

      coachCourseLoading:false,
      coachCourseRegionList:[],
      coachCourseRegionId:[],
      coachCourseStatistical:{
        endDate:"",
        regionId:null,
        startDate:"",
      },//柱状图筛选条件
      coachCourseDate:null,
      coachCourseData: [],
      coachCourseProject:{},
      series: [{data: [40, 50, 100], type: "bar"}],


      countHeader: {
        today:{},
        yesterday:{},
        month:{},
        year:{},
      },
      headerStyle:{
        day:{
          background: '#ECF5FF',
          color: '#409EFF',
          one:'el-icon-s-promotion',
          two:'el-icon-s-custom'
        },
        lastDay:{
          background: '#F0F9EB',
          color: '#67C23A',
          one:'el-icon-s-promotion',
          two:'el-icon-s-custom'
        },
        mouth:{
          background: '#FDF6EC',
          color: '#E6A23C',
          one:'el-icon-s-promotion',
          two:'el-icon-s-custom'
        },
        year:{
          background: '#FEF0F0',
          color: '#F56C6C',
          one:'el-icon-s-promotion',
          two:'el-icon-s-custom'
        }
      },
    }
  },
  computed:{
    websocket() {
      return this.$store.state.webSocket.notifyObject; //这里返回vuex的state里的str数据
    },
  },
  created() {
    // 饼状图数据
    this.getCourseBreadSummary()
    // 获取头部数据
    this.getCourseHeaderSummary()
    //获取柱状图数据
    this.getCourseHistogramSummary()
    //获取教练课时统计
    this.getCoachCourseStatistical()
  },
  methods: {
    getFlag(val){
      console.log(val);
      this.histogram.flag = val
      this.getCourseHistogramSummary();
    },

    selectFlag(){
      this.getCourseBreadSummary()
    },
    getAreaListId(val){
      this.coachCourseStatistical.areaList = val;
      this.getCoachCourseStatistical();
    },
    //柱状图点击事件
    detailed(val){
      this.pieChart.regionId=val.id;
      this.getCourseBreadSummary();
    },
    //柱状图点击事件
    histogramDetailed(val){
      let regionId = this.histogramProject.id[val];
      this.histogram.regionId=regionId;
      this.getCourseHistogramSummary();
    },
    //获取柱状图数据
   async getCourseHistogramSummary(){
      this.histogramLoading=true;
     await courseHistogramSummary(this.histogram).then(val=>{
        this.histogramLoading=false;
        if (val.code == 200) {
          let histogramData = [
            {data: val.data.abnoral, name: "异常学员", type: "bar"},
            {data: val.data.noral, name: "正常学员", type: "bar"},
            {data: val.data.trail, name: "试听学员", type: "bar"},]
          this.histogramData=histogramData;
          this.histogramProject={name:val.data.cityName,id:val.data.cityId}
        } else {
          this.$message.error("获取柱状图统计数据失败");
        }
      })
    },
    //获取饼状图数据
    getCourseBreadSummary(){
      this.pieChartLoading=true;
      courseBreadSummary(this.pieChart).then(res => {
        this.pieChartLoading=false;
        if (res.code == 200) {
          this.pieChartData.data = res.data
        } else {
          this.$message.error("获取饼状图统计数据失败");
        }
      })
    },
    //获取头部数据
    getCourseHeaderSummary(){
      courseHeaderSummary().then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.countHeader = res.data;
        } else {
          this.$message.error("获取顶部统计数据失败");
        }
      })
    },
    //获取教练课时统计
    getCoachCourseStatistical(){
      // console.log(this.coachCourseStatistical)
      coachCourseStatistical(this.coachCourseStatistical).then(val => {
        if (val.code == 200) {
          let coachCourseData = [{data:val.data.numList, type: "bar"}]
          this.coachCourseData=coachCourseData;
          this.coachCourseProject={name:val.data.nameList}

        } else {
          this.$message.error("获取柱状图统计数据失败");
        }
      })
    },

    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    }
  },
  watch:{
    //监听用户选择饼状图时间完成以后进行查询
    pieChartDate(){
      if (this.pieChartDate!=null && this.pieChartDate!="" && this.pieChartDate.length>0){
        this.pieChart.startDate= this.dateFormat(this.pieChartDate[0]);
        this.pieChart.endDate= this.dateFormat(this.pieChartDate[1]);
      }else {
        this.pieChart.startDate="";
        this.pieChart.endDate="";
      }
      this.getCourseBreadSummary();
    },
    //监听用户选择柱状图时间完成以后进行查询
    histogramDate(){
      if (this.histogramDate!=null && this.histogramDate!="" && this.histogramDate.length>0){
        this.histogram.startDate= this.dateFormat(this.histogramDate[0]);
        this.histogram.endDate= this.dateFormat(this.histogramDate[1]);
      }else {
        this.histogram.startDate="";
        this.histogram.endDate="";
      }
      this.getCourseHistogramSummary();
    },
    coachCourseRegionId(){
      let regionId="";
      for (const item of this.coachCourseRegionId) {
        regionId=regionId+item+","
      }
      this.coachCourseStatistical.regionId=regionId;
      this.getCoachCourseStatistical();
    },
    coachCourseDate(){
      this.coachCourseStatistical.startDate= this.dateFormat(this.coachCourseDate[0]);
      this.coachCourseStatistical.endDate= this.dateFormat(this.coachCourseDate[1]);
      this.getCoachCourseStatistical()
    },
    websocket:{
      handler(newVal) {
        // console.log(newVal, oval);//nval改变后的新数据，oval改变前的旧数据
        if (newVal.api=="course"){
          console.log("课时接收到后台传递的信息")
          // 饼状图数据
          this.getCourseBreadSummary()
          // 获取头部数据
          this.getCourseHeaderSummary()
          //获取柱状图数据
          this.getCourseHistogramSummary()
          //获取教练课时统计
          this.getCoachCourseStatistical()
        }
      },
      deep: true, // 深度监听
      immediate: true,//立即执行
    }
  }
}
</script>

<style>
.statistical{
  display: flex;
  justify-content:space-between;
  margin: 10px 0;
}
</style>
<style scoped>
.abow_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
}
.el-dialog {
  margin: 0 auto;
  height: 100%;
  overflow: hidden;}

</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}
.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>
